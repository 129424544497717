module.exports = [{
      plugin: require('/codebuild/output/src1939702133/src/node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/codebuild/output/src1939702133/src/src/intl","languages":["en","fr"],"defaultLanguage":"fr","redirect":true},
    },{
      plugin: require('/codebuild/output/src1939702133/src/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/codebuild/output/src1939702133/src/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
